
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Wallet & Transaction");
    });
  },
});
